<template>
  <main id="homepage">
    <section class="banner">
      <client-only>
        <agile
          ref="bannerCarousel"
          :dots="false"
          :autoplay="true"
          :slides-to-show="1"
          :navButtons="false"
        >
          <div
            :key="image.id"
            v-for="image in page.acf.banner"
            class="relative"
          >
            <a
              :href="
                localePath({
                  name: 'services-slug',
                  params: { slug: image.title.toLowerCase() }
                })
              "
            >
              <div
                class="absolute bottom-0 hidden laptop:block"
                style="left:50%;margin-left:-312.5px"
              >
                <div class="relative text-center">
                  <img
                    :alt="image.title"
                    :src="`/images/kaasstuk-${image.description}.png`"
                    style="width:625px;height:337px"
                  />
                  <div
                    class="absolute"
                    style="left:50%;margin-left:-175px;width:300px;top:105px"
                  >
                    <strong class="text-white text-2xl">{{
                      image.title.toUpperCase()
                    }}</strong>
                  </div>
                  <div
                    class="absolute"
                    style="left:50%;margin-left:-225px;width:400px;top:140px"
                  >
                    <span class="text-white text-4xl">
                      &bull; &bull; &bull;
                    </span>
                    <p v-html="image.caption" class="text-white"></p>
                  </div>
                </div>
              </div>
              <div
                class="absolute bottom-0 hidden tablet:block laptop:hidden"
                style="left:50%;margin-left:-200px"
              >
                <div class="relative text-center">
                  <img
                    :alt="image.title"
                    :src="`/images/kaasstuk-${image.description}.png`"
                    style="width:400px;height:215px"
                  />
                  <div
                    class="absolute"
                    style="left:50%;margin-left:-175px;width:325px;top:50px"
                  >
                    <strong class="text-white text-2xl">{{
                      image.title.toUpperCase()
                    }}</strong>
                  </div>
                  <div
                    class="absolute"
                    style="left:50%;margin-left:-225px;width:425px;top:75px"
                  >
                    <span class="text-white text-4xl">
                      &bull; &bull; &bull;
                    </span>
                    <p v-html="image.caption" class="text-white px-20"></p>
                  </div>
                </div>
              </div>
              <div
                class="absolute bottom-0 block tablet:hidden"
                style="left:50%;margin-left:-100px"
              >
                <div class="relative text-center">
                  <img
                    :alt="image.title"
                    :src="`/images/kaasstuk-${image.description}.png`"
                    style="width:200px;height:110px"
                  />
                  <div
                    class="absolute"
                    style="left:50%;margin-left:-165px;width:325px;top:50px"
                  >
                    <strong class="text-white text-2xl">{{
                      image.title.toUpperCase()
                    }}</strong>
                  </div>
                </div>
              </div>
              <img
                :src="image.url"
                :alt="image.title"
                class="hidden laptop:block"
                style="height: 550px"
              />
              <img
                :src="image.url"
                :alt="image.title"
                class="hidden tablet:block laptop:hidden"
                style="height: 300px"
              />
              <img
                :src="image.url"
                :alt="image.title"
                class="block tablet:hidden"
                style="height: 200px"
              />
            </a>
          </div>
        </agile>
      </client-only>
    </section>
    <section class="content pt-18 pb-32">
      <div class="container flex flex-wrap">
        <a
          :href="page.acf.blocks.block_1.url_1"
          class="w-full my-4 tablet:my-0 tablet:w-1/3"
        >
          <div
            style="border-right: 1px #e6e6e6 solid;"
            class="text-center px-8"
          >
            <img
              :src="page.acf.blocks.block_1.image_1.url"
              alt="icon"
              class="mx-auto"
            />
            <br />
            <h6 class="text-red">
              {{ page.acf.blocks.block_1.title_1.toUpperCase() }}
            </h6>
            <span class="text-lightGrey text-3xl">
              &bull; &bull; &bull;
            </span>
            <br />
            <p v-html="page.acf.blocks.block_1.text_1"></p>
          </div>
        </a>
        <a
          :href="page.acf.blocks.block_2.url_2"
          class="w-full my-4 tablet:my-0 tablet:w-1/3"
        >
          <div
            style="border-right: 1px #e6e6e6 solid;"
            class="text-center px-8"
          >
            <img
              :src="page.acf.blocks.block_2.image_2.url"
              alt="icon"
              class="mx-auto"
            />
            <br />
            <h6 class="text-red">
              {{ page.acf.blocks.block_2.title_2.toUpperCase() }}
            </h6>
            <span class="text-lightGrey text-3xl">
              &bull; &bull; &bull;
            </span>
            <br />
            <p v-html="page.acf.blocks.block_2.text_2"></p>
          </div>
        </a>
        <a
          :href="page.acf.blocks.block_3.url_3"
          class="w-full my-4 tablet:my-0 tablet:w-1/3"
        >
          <div
            style="border-right: 1px #e6e6e6 solid;"
            class="text-center px-8"
          >
            <img
              :src="page.acf.blocks.block_3.image_3.url"
              alt="icon"
              class="mx-auto"
            />
            <br />
            <h6 class="text-red">
              {{ page.acf.blocks.block_3.title_3.toUpperCase() }}
            </h6>
            <span class="text-lightGrey text-3xl">
              &bull; &bull; &bull;
            </span>
            <br />
            <p v-html="page.acf.blocks.block_3.text_3"></p>
          </div>
        </a>
      </div>
    </section>

    <section id="hallmarks">
      <div class="container">
        <div class="customers-title relative flex">
          <hr class="w-full" style="border-color: #E6E6E6" />
          <div class="absolute w-full flex" style="top:-25px">
            <p class="text-lightGrey font-black text-4xl bg-white mx-auto px-4">
              {{ 'Certificeringen'.toUpperCase() }}
            </p>
          </div>
        </div>

        <div class="logos pt-18 pb-32">
          <client-only>
            <agile
              ref="bannerCarousel"
              :dots="false"
              :autoplay="true"
              :slides-to-show="6"
              :infinite="true"
              :navButtons="false"
            >
              <div
                :key="hallmark.id"
                v-for="hallmark in hallmarks"
                style="height: 150px"
                class="flex"
              >
                <img
                  :src="hallmark.acf.logo.url"
                  :alt="hallmark.acf.logo.id"
                  class="m-auto"
                />
              </div>
            </agile>
          </client-only>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Homepage',
  head() {
    const path = this.$route.fullPath.split('/')
    const cleanPath = path.map((p) => {
      return p.replace(/-/g, ' ')
    })
    const title =
      cleanPath[cleanPath.length - 1].charAt(0).toUpperCase() +
      cleanPath[cleanPath.length - 1].substring(1)

    return {
      title: `${
        title.length > 0 && title !== 'Nl' && title !== 'En'
          ? `${title} - Cor Segers Groep`
          : 'Cor Segers Groep'
      }`,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'description',
          name: 'description',
          content: 'Cor Segers Groep'
        }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.png' }]
    }
  },
  async asyncData({ store }) {
    const [page, hallmarks] = await Promise.all([
      wordpress.get(
        `page/${
          store.state.language !== undefined ? `${store.state.language}/` : ''
        }homepage`
      ),
      wordpress.get(`category/keurmerken?per_page=999`)
    ])

    return {
      page: page.data,
      hallmarks: hallmarks.data
    }
  }
}
</script>
