<template>
  <main id="about-us">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section class="content container flex flex-wrap content pt-12 pb-24">
      <div class="w-full laptop:w-1/3 order-2 laptop:order-1 p-4 laptop:p-0">
        <div class="blog">
          <h2 class="pl-8 mb-6 text-red" style="font-size: 24px">
            BLOG
          </h2>
          <div v-for="item in blog" class="pl-8 flex">
            <div class="w-1/12">
              <p class="text-xl font-bold">
                &bull;
              </p>
            </div>
            <div class="w-5/6">
              <p :key="item.id">
                <a
                  :href="`/${$store.state.language}/blog/${item.slug}`"
                  v-html="item.title"
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full laptop:w-2/3 order-1 laptop:order-2 p-4 laptop:p-0"
        style="border-left: 1px #ededed solid"
      >
        <h1 class="pl-8 text-red" style="font-size: 24px">
          {{ page.title.toUpperCase() }}
        </h1>
        <div v-html="page.content" class="pl-8 mt-6 text-darkGrey"></div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'AboutUs',
  async asyncData({ route, store }) {
    const [page, blog] = await Promise.all([
      wordpress.get(`page${route.fullPath}`),
      wordpress.get(
        `category/${
          store.state.language === 'nl' ? 'blog' : 'blog-en'
        }?per_page=999&orderby=menu_order`
      )
    ])

    return {
      page: page.data,
      blog: blog.data.reverse()
    }
  }
}
</script>
