<template>
  <main id="vacancies">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section class="content pt-18 pb-32">
      <div class="container flex flex-wrap">
        <div class="px-8 order-1 laptop:order-2 w-full mb-12">
          <div v-html="page.content" class="w-full mb-12"></div>

          <div v-for="vacancy in vacancies">
            <a
              :href="
                localePath({
                  name: 'vacancies-slug',
                  params: { slug: vacancy.slug }
                })
              "
              class="flex flex-wrap"
            >
              <div class="w-full tablet:w-1/4 tablet:h-full flex tablet:pr-4">
                <img
                  :src="vacancy.acf.image.sizes.medium_large"
                  alt="icon"
                  class="m-auto"
                  style="width: 100%;max-height: 200px;object-fit: cover;"
                />
              </div>
              <div
                class="w-full tablet:w-3/4 text-center tablet:text-left tablet:pl-4"
              >
                <h6>{{ vacancy.title.toUpperCase() }}</h6>
                <span class="text-grey text-3xl">
                  &bull; &bull; &bull;
                </span>
                <br />
                <div
                  v-html="
                    `${vacancy.acf.text.slice(
                      0,
                      254
                    )}<br><strong>Lees verder...</strong>`
                  "
                  class="text-left"
                ></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Vacancies',
  async asyncData({ route, store }) {
    const [page, vacancies] = await Promise.all([
      wordpress.get(`page${route.fullPath}`),
      wordpress.get(
        `category/${
          store.state.language === 'nl' ? 'vacatures' : 'vacatures-en'
        }?per_page=999&orderby=menu_order`
      )
    ])

    return {
      page: page.data,
      vacancies: vacancies.data
    }
  }
}
</script>
