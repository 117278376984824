<template>
  <main id="blog-detail">
    <section class="banner">
      <img :src="post.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section class="content container flex flex-wrap content pt-12 pb-24">
      <div class="w-full laptop:w-1/3 order-2 laptop:order-1 p-4 laptop:p-0">
        <div class="blog">
          <h2 class="pl-8 mb-6 text-red" style="font-size: 24px">
            VACATURES
          </h2>
          <div v-for="vacancy in vacancies" class="pl-8 flex">
            <div class="w-1/12">
              <p class="text-xl font-bold">
                &bull;
              </p>
            </div>
            <div class="w-5/6">
              <p :key="vacancy.id">
                <a
                  :href="
                    localePath({
                      name: 'vacancies-slug',
                      params: { slug: vacancy.slug }
                    })
                  "
                  v-html="vacancy.title"
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full laptop:w-2/3 order-1 laptop:order-2 p-4 laptop:p-0"
        style="border-left: 1px #ededed solid"
      >
        <h1 class="pl-8 text-red" style="font-size: 24px">
          {{ post.title.toUpperCase() }}
        </h1>
        <div v-html="post.acf.text" class="pl-8 mt-6"></div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'BlogDetail',
  async asyncData({ route, error, store }) {
    const [vacancies] = await Promise.all([
      wordpress.get(
        `category/${
          store.state.language === 'nl' ? 'vacatures' : 'vacatures-en'
        }?per_page=999&orderby=menu_order`
      )
    ])

    return {
      vacancies: vacancies.data.reverse(),
      post: await wordpress
        .get(`post/${route.params.slug}`)
        .then((res) => {
          if (res.data.title) {
            return res.data
          } else {
            error({ statusCode: 404, message: 'Post not found' })
          }
        })
        .catch((e) => {
          error({ statusCode: 404, message: 'Post not found' })
        })
    }
  }
}
</script>
