<template>
  <main id="services">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section id="submenu" class="submenu submenu-red bg-yellow">
      <div class="container">
        <div id="submenu-content" class="submenu-content">
          <ul class="flex flex-wrap text-center">
            <li
              class="w-full tablet:w-1/3 item mx-auto"
              style="margin-top: 15px;"
            >
              <a
                :href="
                  localePath({
                    name: 'services-slug',
                    params: { slug: 'veredeling' }
                  })
                "
                class="text-white text-3xl"
                >VEREDELING</a
              >
            </li>
            <li
              class="w-full tablet:w-1/3 item mx-auto"
              style="margin-top: 15px;"
            >
              <a
                :href="
                  localePath({
                    name: 'services-slug',
                    params: { slug: 'verpakking' }
                  })
                "
                class="text-white text-3xl"
                >VERPAKKING</a
              >
            </li>
            <li
              class="w-full tablet:w-1/3 item mx-auto"
              style="margin-top: 15px;"
            >
              <a
                :href="
                  localePath({
                    name: 'services-slug',
                    params: { slug: 'transport' }
                  })
                "
                class="text-white text-3xl"
                >TRANSPORT</a
              >
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="content pt-18 pb-32">
      <div class="container flex flex-wrap">
        <div v-for="service in services">
          <div v-if="service.slug === 'diensten'">
            <div class="px-8 w-full mb-12 flex flex-wrap">
              <div class="w-full tablet:w-1/4 tablet:h-full flex">
                <img
                  :src="service.acf.image.sizes.medium_large"
                  alt="icon"
                  class="m-auto"
                />
              </div>
              <div class="w-full tablet:w-3/4 text-center tablet:text-left">
                <h6>{{ service.title.toUpperCase() }}</h6>
                <span class="text-grey text-3xl">
                  &bull; &bull; &bull;
                </span>
                <br />
                <div v-html="service.acf.text" class="text-left"></div>
              </div>
            </div>
          </div>
          <div v-else>
            <a
              :href="`/${$store.state.language}/diensten/${service.slug}`"
              class="px-8 w-full mb-12 flex flex-wrap"
            >
              <div class="w-full tablet:w-1/4 tablet:h-full flex">
                <img
                  :src="service.acf.image.sizes.medium_large"
                  alt="icon"
                  class="m-auto"
                />
              </div>
              <div class="w-full tablet:w-3/4 text-center tablet:text-left">
                <h6>{{ service.title.toUpperCase() }}</h6>
                <span class="text-grey text-3xl">
                  &bull; &bull; &bull;
                </span>
                <br />
                <div v-html="service.acf.text" class="text-left"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Services',
  async asyncData({ route, store }) {
    const [page, services] = await Promise.all([
      wordpress.get(`page${route.fullPath}`),
      wordpress.get(
        `category/${
          store.state.language === 'nl' ? 'diensten' : 'diensten-en'
        }?per_page=999`
      )
    ])

    return {
      page: page.data,
      services: services.data
    }
  }
}
</script>
