var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"services"}},[_c('section',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.page.acf.banner.url,"alt":"Cor Segers Groep banner"}})]),_vm._v(" "),_c('section',{staticClass:"submenu submenu-red bg-yellow",attrs:{"id":"submenu"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"submenu-content",attrs:{"id":"submenu-content"}},[_c('ul',{staticClass:"flex flex-wrap text-center"},[_c('li',{staticClass:"w-full tablet:w-1/3 item mx-auto",staticStyle:{"margin-top":"15px"}},[_c('a',{staticClass:"text-white text-3xl",attrs:{"href":_vm.localePath({
                  name: 'services-slug',
                  params: { slug: 'veredeling' }
                })}},[_vm._v("VEREDELING")])]),_vm._v(" "),_c('li',{staticClass:"w-full tablet:w-1/3 item mx-auto",staticStyle:{"margin-top":"15px"}},[_c('a',{staticClass:"text-white text-3xl",attrs:{"href":_vm.localePath({
                  name: 'services-slug',
                  params: { slug: 'verpakking' }
                })}},[_vm._v("VERPAKKING")])]),_vm._v(" "),_c('li',{staticClass:"w-full tablet:w-1/3 item mx-auto",staticStyle:{"margin-top":"15px"}},[_c('a',{staticClass:"text-white text-3xl",attrs:{"href":_vm.localePath({
                  name: 'services-slug',
                  params: { slug: 'transport' }
                })}},[_vm._v("TRANSPORT")])])])])])]),_vm._v(" "),_c('section',{staticClass:"content pt-18 pb-32"},[_c('div',{staticClass:"container flex flex-wrap"},_vm._l((_vm.services),function(service){return _c('div',[(service.slug === 'diensten')?_c('div',[_c('div',{staticClass:"px-8 w-full mb-12 flex flex-wrap"},[_c('div',{staticClass:"w-full tablet:w-1/4 tablet:h-full flex"},[_c('img',{staticClass:"m-auto",attrs:{"src":service.acf.image.sizes.medium_large,"alt":"icon"}})]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4 text-center tablet:text-left"},[_c('h6',[_vm._v(_vm._s(service.title.toUpperCase()))]),_vm._v(" "),_c('span',{staticClass:"text-grey text-3xl"},[_vm._v("\n                • • •\n              ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(service.acf.text)}})])])]):_c('div',[_c('a',{staticClass:"px-8 w-full mb-12 flex flex-wrap",attrs:{"href":("/" + (_vm.$store.state.language) + "/diensten/" + (service.slug))}},[_c('div',{staticClass:"w-full tablet:w-1/4 tablet:h-full flex"},[_c('img',{staticClass:"m-auto",attrs:{"src":service.acf.image.sizes.medium_large,"alt":"icon"}})]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4 text-center tablet:text-left"},[_c('h6',[_vm._v(_vm._s(service.title.toUpperCase()))]),_vm._v(" "),_c('span',{staticClass:"text-grey text-3xl"},[_vm._v("\n                • • •\n              ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(service.acf.text)}})])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }