<template>
  <main></main>
</template>

<script>
export default {
  name: 'Error',
  // eslint-disable-next-line vue/require-prop-types
  props: ['error'],
  layout: 'default'
}
</script>
