<template>
  <main id="regular-content">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section class="content container flex content pt-12 pb-24">
      <div class="w-full" style="border-left: 1px #ededed solid">
        <h1 class="pl-8 text-red" style="font-size: 24px">
          {{ page.title.toUpperCase() }}
        </h1>
        <div v-html="page.content" class="pl-8 mt-6 text-darkGrey"></div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'ContentPage',
  async asyncData({ route }) {
    const page = await wordpress.get(`page${route.fullPath}`)

    return {
      page: page.data
    }
  }
}
</script>
