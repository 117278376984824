var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"vacancies"}},[_c('section',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.page.acf.banner.url,"alt":"Cor Segers Groep banner"}})]),_vm._v(" "),_c('section',{staticClass:"content pt-18 pb-32"},[_c('div',{staticClass:"container flex flex-wrap"},[_c('div',{staticClass:"px-8 order-1 laptop:order-2 w-full mb-12"},[_c('div',{staticClass:"w-full mb-12",domProps:{"innerHTML":_vm._s(_vm.page.content)}}),_vm._v(" "),_vm._l((_vm.vacancies),function(vacancy){return _c('div',[_c('a',{staticClass:"flex flex-wrap",attrs:{"href":_vm.localePath({
                name: 'vacancies-slug',
                params: { slug: vacancy.slug }
              })}},[_c('div',{staticClass:"w-full tablet:w-1/4 tablet:h-full flex tablet:pr-4"},[_c('img',{staticClass:"m-auto",staticStyle:{"width":"100%","max-height":"200px","object-fit":"cover"},attrs:{"src":vacancy.acf.image.sizes.medium_large,"alt":"icon"}})]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4 text-center tablet:text-left tablet:pl-4"},[_c('h6',[_vm._v(_vm._s(vacancy.title.toUpperCase()))]),_vm._v(" "),_c('span',{staticClass:"text-grey text-3xl"},[_vm._v("\n                • • •\n              ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(
                  ((vacancy.acf.text.slice(
                    0,
                    254
                  )) + "<br><strong>Lees verder...</strong>")
                )}})])])])})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }