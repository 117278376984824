<template>
  <main id="quality">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section class="content container flex flex-wrap content pt-12 pb-24">
      <div class="w-full laptop:w-1/3 order-2 laptop:order-1 p-4 laptop:p-0">
        <div class="certificate">
          <h2 class="pl-8 mb-6 text-red" style="font-size: 24px">
            CERTIFICATEN
          </h2>
          <div v-for="certificate in certificates" class="pl-8 flex">
            <div class="w-1/12">
              <p class="text-xl font-bold">
                &bull;
              </p>
            </div>
            <div class="w-5/6">
              <p :key="certificate.id">
                <a
                  :href="certificate.acf.certificate.url"
                  v-html="certificate.title"
                  target="_blank"
                ></a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full laptop:w-2/3 order-1 laptop:order-2 p-4 laptop:p-0"
        style="border-left: 1px #ededed solid"
      >
        <h1 class="pl-8 text-red" style="font-size: 24px">
          {{ page.title.toUpperCase() }}
        </h1>
        <div v-html="page.content" class="pl-8 mt-6 text-darkGrey"></div>
      </div>
    </section>

    <section id="hallmarks">
      <div class="container">
        <div class="customers-title relative flex">
          <hr class="w-full" style="border-color: #E6E6E6" />
          <div class="absolute w-full flex" style="top:-25px">
            <p class="text-lightGrey font-black text-4xl bg-white mx-auto px-4">
              {{ 'Certificeringen'.toUpperCase() }}
            </p>
          </div>
        </div>

        <div class="logos pt-18 pb-32">
          <client-only>
            <agile
              ref="bannerCarousel"
              :dots="false"
              :autoplay="true"
              :slides-to-show="6"
              :infinite="true"
              :navButtons="false"
            >
              <div
                :key="hallmark.id"
                v-for="hallmark in hallmarks"
                style="height: 150px"
                class="flex"
              >
                <img
                  :src="hallmark.acf.logo.url"
                  alt="logo block"
                  class="m-auto"
                />
              </div>
              <div
                :key="hallmark.id"
                v-for="hallmark in hallmarks"
                style="height: 150px"
                class="flex"
              >
                <img
                  :src="hallmark.acf.logo.url"
                  alt="logo block"
                  class="m-auto"
                />
              </div>
              <div
                :key="hallmark.id"
                v-for="hallmark in hallmarks"
                style="height: 150px"
                class="flex"
              >
                <img
                  :src="hallmark.acf.logo.url"
                  alt="logo block"
                  class="m-auto"
                />
              </div>
            </agile>
          </client-only>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Quality',
  async asyncData({ route }) {
    const [page, hallmarks, certificates] = await Promise.all([
      wordpress.get(`page${route.fullPath}`),
      wordpress.get(`category/keurmerken?per_page=999&orderby=menu_order`),
      wordpress.get(`category/certificaten?per_page=999&orderby=menu_order`)
    ])

    return {
      page: page.data,
      hallmarks: hallmarks.data,
      certificates: certificates.data.reverse()
    }
  }
}
</script>
