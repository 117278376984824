<template>
  <header>
    <section class="top-header flex container">
      <div class="w-1/2 laptop:w-1/4 flex">
        <a :href="localePath('index')" class="mr-auto my-auto">
          <img
            src="~/assets/images/logos/logo.svg"
            alt="Cor Segers Groep logo"
            style="width: 150px;"
            class="my-4 p-4 tablet:p-0"
          />
        </a>
      </div>
      <div class="hidden laptop:flex laptop:flex-wrap w-3/4 relative">
        <!--        <div class="w-full flex pt-2">-->
        <!--          <ul class="flex ml-auto mb-auto">-->
        <!--            <span class="text-grey font-medium cursor-pointer">NL</span>-->
        <!--            <span class="text-grey font-medium mx-2">|</span>-->
        <!--            <span class="text-grey font-medium cursor-pointer">EN</span>-->
        <!--          </ul>-->
        <!--        </div>-->
        <div class="w-full flex">
          <ul class="flex ml-auto mt-auto">
            <li class="mr-12 mb-2">
              <a :href="localePath('index')">
                <i class="fas fa-home"></i>
              </a>
            </li>
            <li class="extracss mr-12 mb-2">
              <a :href="localePath('about-us')">
                {{ 'Over ons'.toUpperCase() }}
              </a>
            </li>
            <li class="extracss mr-12 mb-2">
              <a :href="localePath('services')">
                {{ 'Diensten'.toUpperCase() }}
              </a>
            </li>
            <li class="extracss mr-12 mb-2">
              <a :href="localePath('quality')">
                {{ 'Kwaliteit'.toUpperCase() }}
              </a>
            </li>
            <li v-if="vacanciesActivity" class="extracss mr-12 mb-2">
              <a :href="localePath('vacancies')">
                {{ 'Vacatures'.toUpperCase() }}
              </a>
            </li>
            <li class="extracss mr-12 mb-2">
              <a :href="localePath('blog')">
                {{ 'Blog'.toUpperCase() }}
              </a>
            </li>
            <li class="extracss mb-2">
              <a :href="localePath('contact')">
                {{ 'Contact'.toUpperCase() }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="inline-block laptop:hidden w-1/2 flex">
        <span
          class="w-full tablet:w-1/2 flex before home-icon pl-2 tablet:pl-4"
        >
          <div
            :class="{ open: showMenu }"
            @click="changeMobilemenu"
            class="hamburger"
          >
            <span />
            <span />
            <span />
            <span />
          </div>
        </span>
      </div>
    </section>

    <section
      v-show-slide="showMenu"
      class="uncollapsed-menu z-10 bg-white absolute"
    >
      <div class="menu-content">
        <div class="text-2xl text-white">
          <a :href="localePath('index')" class="font-semibold text-grey">
            {{ 'Home'.toUpperCase() }}
          </a>
          <br />
          <hr class="my-2" style="border-color:#E6E6E6" />
          <a :href="localePath('about-us')" class="font-semibold text-grey">
            {{ 'Over ons'.toUpperCase() }}
          </a>
          <br />
          <hr class="my-2" style="border-color:#E6E6E6" />
          <a :href="localePath('services')" class="font-semibold text-grey">
            {{ 'Diensten'.toUpperCase() }}
          </a>
          <br />
          <hr class="my-2" style="border-color:#E6E6E6" />
          <a :href="localePath('quality')" class="font-semibold text-grey">
            {{ 'Kwaliteit'.toUpperCase() }}
          </a>
          <br />
          <hr class="my-2" style="border-color:#E6E6E6" />
          <a
            v-if="vacanciesActivity"
            :href="localePath('vacancies')"
            class="font-semibold text-grey"
          >
            {{ 'Vacatures'.toUpperCase() }}
          </a>
          <br v-if="vacanciesActivity" />
          <hr
            v-if="vacanciesActivity"
            class="my-2"
            style="border-color:#E6E6E6"
          />
          <a :href="localePath('blog')" class="font-semibold text-grey">
            {{ 'Blog'.toUpperCase() }}
          </a>
          <br />
          <hr class="my-2" style="border-color:#E6E6E6" />
          <a :href="localePath('contact')" class="font-semibold text-grey">
            {{ 'Contact'.toUpperCase() }}
          </a>
          <hr class="my-2" style="border-color:#E6E6E6" />
          <!--          <ul class="text-center">-->
          <!--            <span class="text-darkGrey font-bold cursor-pointer">NL</span>-->
          <!--            <span class="text-grey font-semibold mx-2">|</span>-->
          <!--            <span class="text-grey font-semibold cursor-pointer">EN</span>-->
          <!--          </ul>-->
        </div>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    vacanciesActivity() {
      return this.$store.getters.getVacancyActivityState
    }
  },
  watch: {
    $route() {
      this.showMenu = false
    }
  },
  methods: {
    changeMobilemenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
