<template>
  <main id="contact">
    <section class="banner relative hidden tablet:block">
      <img
        :src="page.acf.banner.url"
        alt="Cor Segers Groep banner"
        style="height:auto"
      />
      <div @click="active = 'hoofdkantoor-de-mortel'" class="cursor-pointer">
        <img
          class="w-full h-full absolute"
          style="left:49.65%;bottom:64.5%;object-fit:none;height:40px;width:40px;"
          src="/images/aanwijskaas.png"
          alt="pointer"
        />
      </div>
      <div @click="active = 'beek-en-donk'" class="cursor-pointer">
        <img
          class="w-full h-full absolute"
          style="left: 43%;bottom: 65.5%;object-fit:none;height:40px;width:40px;"
          src="/images/aanwijskaas.png"
          alt="pointer"
        />
      </div>
      <div @click="active = 'gemert'" class="cursor-pointer">
        <img
          class="w-full h-full absolute"
          style="left: 49%;bottom: 73.5%;object-fit:none;height:40px;width:40px;"
          src="/images/aanwijskaas.png"
          alt="pointer"
        />
      </div>
      <div @click="active = 'geldrop'" class="cursor-pointer">
        <img
          class="w-full h-full absolute"
          style="left: 37.65%;bottom: 21%;object-fit:none;height:40px;width:40px;"
          src="/images/aanwijskaas.png"
          alt="pointer"
        />
      </div>
      <div @click="active = 'deurne'" class="cursor-pointer">
        <img
          class="w-full h-full absolute"
          style="left: 55.65%;bottom: 30.5%;object-fit:none;height:40px;width:40px;"
          src="/images/aanwijskaas.png"
          alt="pointer"
        />
      </div>
    </section>
    <section
      class="content container flex flex-wrap content tablet:pt-12 pb-24"
    >
      <div class="w-full tablet:w-1/3 order-2 tablet:order-1 p-4 tablet:pr-4">
        <h1 style="font-size: 24px;" class="text-red">
          {{ `Contact`.toUpperCase() }}
        </h1>
        <p>
          <small>
            Vul onderstaand formulier in en wij nemen <br />
            zsm contact met u op.
          </small>
        </p>
        <div class="form pt-4">
          <form @submit.prevent="sendContactForm" name="contact">
            <div class="pb-2">
              <input
                id="name"
                v-model="form.name"
                class="w-full text-darkGrey bg-lightGrey py-2 px-4"
                type="text"
                placeholder="naam"
                name="name"
                required
              />
            </div>
            <div class="pb-2">
              <input
                id="address"
                v-model="form.address"
                class="w-full text-darkGrey bg-lightGrey py-2 px-4"
                type="text"
                placeholder="adres"
                name="address"
                required
              />
            </div>
            <div class="pb-2">
              <input
                id="postalcode"
                v-model="form.postcode"
                class="w-full text-darkGrey bg-lightGrey py-2 px-4"
                type="text"
                placeholder="postcode"
                name="postalcode"
                required
              />
            </div>
            <div class="pb-2">
              <input
                id="plaats"
                v-model="form.place"
                class="w-full text-darkGrey bg-lightGrey py-2 px-4"
                type="text"
                placeholder="plaats"
                name="plaats"
                required
              />
            </div>
            <div class="pb-2">
              <input
                id="email"
                v-model="form.email"
                class="w-full text-darkGrey bg-lightGrey py-2 px-4"
                type="email"
                placeholder="e-mail"
                name="email"
                required
              />
            </div>
            <div class="pb-2">
              <textarea
                id="message"
                v-model="form.message"
                class="w-full text-darkGrey bg-lightGrey py-2 px-4"
                rows="4"
                cols="50"
                placeholder="bericht"
                name="message"
                required
              ></textarea>
            </div>

            {{ message }}

            <button class="w-full text-white bg-red py-2 px-4">
              <span>
                {{ 'Verzenden'.toUpperCase() }}
                <i v-if="loading" class="fas fa-circle-notch fa-spin" />
              </span>
            </button>
          </form>
        </div>
      </div>
      <div
        class="w-full tablet:w-2/3 order-1 tablet:order-2 p-4 tablet:pl-4"
        style="border-left: 1px #ededed solid"
      >
        <div v-for="branche in branches" class="pb-8">
          <div
            v-show-slide="active === branche.slug"
            @click="active = ''"
            class="cursor-pointer"
          >
            <img
              :src="branche.acf.image.url"
              :alt="branche.slug"
              style="height: 350px"
              class="w-full object-cover"
            />

            <div class="bg-yellow p-4">
              <h6
                v-html="branche.acf.location.toUpperCase()"
                class="text-red text-4xl font-bold"
              ></h6>
              <p v-html="branche.acf.address" style="color:#E42613"></p>
              <p v-html="branche.acf.extra_info" style="color:#E42613"></p>
            </div>
          </div>
          <div
            v-show-slide="active !== branche.slug"
            @click="active = branche.slug"
            class="relative cursor-pointer"
          >
            <img
              :src="branche.acf.image.url"
              :alt="branche.slug"
              style="height: 150px"
              class="w-full object-cover"
            />

            <div class="absolute" style="bottom:0;left:10px">
              <h6
                v-html="branche.acf.location.toUpperCase()"
                class="text-white text-4xl font-bold"
              ></h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Contact',
  data() {
    return {
      active: '',
      loading: false,
      message: '',
      form: {
        name: '',
        address: '',
        postcode: '',
        place: '',
        email: '',
        message: ''
      }
    }
  },
  async asyncData({ route }) {
    const [page, branches] = await Promise.all([
      wordpress.get(`page${route.fullPath}`),
      wordpress.get(`category/vestigingen?per_page=999`)
    ])

    return {
      page: page.data,
      branches: branches.data
    }
  },
  methods: {
    sendContactForm() {
      const params = new URLSearchParams()

      params.append('your-name', this.form.name)
      params.append('your-address', this.form.address)
      params.append('your-postcode', this.form.postcode)
      params.append('your-place', this.form.place)
      params.append('your-email', this.form.email)
      params.append('your-message', this.form.message)
      this.$axios
        .post(
          'https://corsegers-wp.rosegaar.work/cms/wp-json/contact-form-7/v1/contact-forms/5/feedback',
          params,
          { 'Content-Type': 'application/x-www-form-urlencoded' }
        )
        .then(() => {
          this.loading = false
          this.message =
            'Bedankt, wij nemen zo snel mogelijk contact met je op!'
          this.form = {
            name: '',
            address: '',
            postcode: '',
            place: '',
            email: '',
            message: ''
          }
        })
        .catch(() => {
          this.loading = false
          this.message = 'Iets ging er niet goed, probeer het later nog eens...'
        })
    }
  }
}
</script>
