var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"blog"}},[_c('section',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.page.acf.banner.url,"alt":"Cor Segers Groep banner"}})]),_vm._v(" "),_c('section',{staticClass:"content pt-18 pb-32"},[_c('div',{staticClass:"container flex flex-wrap"},_vm._l((_vm.blog),function(blogItem){return _c('div',{staticClass:"px-8 w-full mb-12"},[_c('a',{staticClass:"flex flex-wrap",attrs:{"href":_vm.localePath({
              name: 'blog-slug',
              params: { slug: blogItem.slug }
            })}},[_c('div',{staticClass:"w-full tablet:w-1/4 tablet:h-full flex tablet:pr-4"},[_c('img',{staticClass:"m-auto",staticStyle:{"width":"100%","height":"200px","object-fit":"cover"},attrs:{"src":blogItem.acf.image.sizes.medium_large,"alt":"icon"}})]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4 text-center tablet:text-left tablet:pl-4"},[_c('h6',{staticClass:"mt-6 tablet:mt-0"},[_vm._v("\n              "+_vm._s(blogItem.title.toUpperCase())+"\n            ")]),_vm._v(" "),_c('span',{staticClass:"text-grey text-3xl"},[_vm._v("\n              • • •\n            ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"text-left",domProps:{"innerHTML":_vm._s(
                ((blogItem.acf.text.slice(
                  0,
                  255
                )) + "...    <br><strong>Lees verder...</strong>")
              )}})])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }