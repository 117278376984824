<template>
  <footer>
    <section class="contact bg-lightGrey">
      <div class="container flex flex-wrap">
        <div
          class="w-full text-center laptop:text-left tablet:w-full laptop:w-1/4"
        >
          <ul>
            <li class="-mt-18 flex">
              <img
                src="~assets/images/cheese.png"
                class="mx-auto laptop:mr-auto laptop:ml-0"
                alt="cheese"
              />
            </li>
          </ul>
          <ul>
            <li class="mb-2">
              <span class="text-grey">
                2020 | Cor Segers Groep ©
              </span>
            </li>
          </ul>
        </div>
        <div
          class="w-full text-center tablet:text-left tablet:pt-16 pb-8 tablet:w-1/3 laptop:w-1/4"
        >
          <ul>
            <li>
              <strong class="text-darkGrey">
                {{ 'csg'.toUpperCase() }}
              </strong>
            </li>
            <li class="mb-2">
              <span class="text-white text-3xl">
                &bull; &bull; &bull;
              </span>
            </li>
            <li class="mb-2">
              <a :href="localePath('about-us')" class="text-grey">
                {{ 'over ons' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('blog')" class="text-grey">
                {{ 'blog' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('services')" class="text-grey">
                {{ 'diensten' }}
              </a>
            </li>
            <li class="mb-2">
              <a
                :href="
                  localePath({
                    name: 'services-slug',
                    params: { slug: 'veredeling' }
                  })
                "
                class="text-grey"
              >
                {{ 'veredeling' }}
              </a>
            </li>
            <li class="mb-2">
              <a
                :href="
                  localePath({
                    name: 'services-slug',
                    params: { slug: 'verpakking' }
                  })
                "
                class="text-grey"
              >
                {{ 'verpakking' }}
              </a>
            </li>
            <li class="mb-2">
              <a
                :href="
                  localePath({
                    name: 'services-slug',
                    params: { slug: 'transport' }
                  })
                "
                class="text-grey"
              >
                {{ 'transport' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('quality')" class="text-grey">
                {{ 'kwaliteit' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('vacancies')" class="text-grey">
                {{ 'vacatures' }}
              </a>
            </li>
          </ul>
        </div>
        <div
          class="w-full text-center tablet:text-left tablet:pt-16 pb-8 tablet:w-1/3 laptop:w-1/4"
        >
          <ul>
            <li>
              <strong class="text-darkGrey">
                {{ 'vestigingen'.toUpperCase() }}
              </strong>
            </li>
            <li class="mb-2">
              <span class="text-white text-3xl">
                &bull; &bull; &bull;
              </span>
            </li>
            <li class="mb-2">
              <a :href="localePath('contact')" class="text-grey">
                {{ 'Hoofdkantoor De Mortel' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('contact')" class="text-grey">
                {{ 'Gemert' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('contact')" class="text-grey">
                {{ 'Deurne' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('contact')" class="text-grey">
                {{ 'Geldrop' }}
              </a>
            </li>
            <li class="mb-2">
              <a :href="localePath('contact')" class="text-grey">
                {{ 'Beek en Donk' }}
              </a>
            </li>
          </ul>
        </div>
        <div
          class="w-full text-center tablet:text-left tablet:pt-16 pb-8 tablet:w-1/3 laptop:w-1/4"
        >
          <ul>
            <li>
              <strong class="text-darkGrey">
                {{ 'contact'.toUpperCase() }}
              </strong>
            </li>
            <li class="mb-2">
              <span class="text-white text-3xl">
                &bull; &bull; &bull;
              </span>
            </li>
            <li class="mb-2">
              <strong class="text-darkGrey">
                {{ 'Cor Segers B.V.' }}
              </strong>
            </li>
            <li class="mb-2">
              <span class="text-grey">
                {{ 'Leeuwerikweg 18' }}
              </span>
            </li>
            <li class="mb-8">
              <span class="text-grey">
                {{ '5425 RW De Mortel' }}
              </span>
            </li>
            <li class="mb-2">
              <strong class="text-darkGrey">
                {{ 'Bezoekadres Hoofdkantoor:' }}
              </strong>
            </li>
            <li class="mb-2">
              <span class="text-grey">
                {{ 'Leeuwerikweg 18' }}
              </span>
            </li>
            <li class="mb-8">
              <span class="text-grey">
                {{ '5425 RW De Mortel' }}
              </span>
            </li>
            <li class="mb-2">
              <strong class="mr-4 text-darkGrey">E</strong>
              <a class="text-grey" href="mailto:info@corsegers.nl">{{
                'info@corsegers.nl'
              }}</a>
            </li>
            <li class="mb-2">
              <strong class="mr-4 text-darkGrey">T</strong>
              <a class="text-grey" href="tel:0031492371271">{{
                '+31 (0)492 - 371271'
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="footer bg-grey">
      <div class="container px-4 tablet:px-0 py-6">
        <p class="text-white">
          <a :href="localePath('privacy-statement')">privacy statement</a>
          |
          <a :href="localePath('disclaimer')">disclaimer</a>
        </p>
      </div>
    </section>
    <client-only>
      <cookie-law theme="dark-lime">
        <div slot="message">
          Wij maken op deze website gebruik van cookies. U kunt deze cookies
          uitzetten via uw browser maar dit kan het functioneren van onze
          website negatief aantasten.
        </div>
      </cookie-law>
    </client-only>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
.Cookie__content,
.Cookie--dark-lime {
  background: #808080 !important;
  color: #e6e6e6 !important;
}
.Cookie__button {
  background: #e6e6e6 !important;
  color: #808080 !important;
}
</style>
