<template>
  <div>
    <header-component />
    <nuxt />
    <footer-component />
    <div
      v-if="scrolled"
      v-scroll-to="'.top-header'"
      class="to-top h-10 w-10 fixed text-center text-red hover:text-red"
    >
      <i class="fas fa-caret-up cursor-pointer text-6xl" />
    </div>
  </div>
</template>

<script>
import headerComponent from '~/components/header.vue'
import footerComponent from '~/components/footer.vue'

export default {
  components: {
    headerComponent,
    footerComponent
  },
  head() {
    const path = this.$route.fullPath.split('/')
    const cleanPath = path.map((p) => {
      return p.replace(/-/g, ' ')
    })
    const title =
      cleanPath[cleanPath.length - 1].charAt(0).toUpperCase() +
      cleanPath[cleanPath.length - 1].substring(1)

    return {
      title: `${
        title.length > 0 && title !== 'Nl' && title !== 'En'
          ? `${title} - Cor Segers Groep`
          : 'Cor Segers Groep'
      }`,
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          hid: 'description',
          name: 'description',
          content: 'Cor Segers Groep'
        }
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.png' },
        {
          rel: 'alternate',
          hreflang: this.language,
          href: `https://www.corsegers.nl${this.$route.fullPath}`
        }
      ]
    }
  },
  data() {
    return {
      scrolled: false
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrolling)
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkScrolling)
  },
  methods: {
    checkScrolling() {
      this.scrolled = window.scrollY > 200
    }
  }
}
</script>

<style>
.to-top {
  z-index: 999;
  bottom: 25px;
  right: 20px;
}
.agile {
  position: relative;
}
.agile--rtl .agile__actions,
.agile--rtl .agile__dots,
.agile--rtl .agile__slides,
.agile--rtl .agile__track {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.agile:active,
.agile :active,
.agile:focus,
.agile :focus {
  outline: none;
}
.agile__list {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.category-carousel .agile__actions {
  margin-left: -2rem;
  margin-right: -2rem;
}
.agile__track {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.agile__actions,
.agile__track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.agile__actions {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.agile__slides {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.agile--disabled .agile__slides {
  display: block;
}
.agile__slide {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.agile--fade .agile__slide {
  opacity: 0;
  position: relative;
  z-index: 0;
}
.agile--fade .agile__slide--active {
  opacity: 1;
  z-index: 2;
}
.agile--fade .agile__slide--expiring {
  opacity: 1;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  z-index: 1;
}
.agile__nav-button {
  margin-top: -50%;
}
.agile__nav-button[disabled] {
  cursor: default;
}
.agile__dots {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
}
.agile__dot button {
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
}
</style>
