<template>
  <main id="blog">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Cor Segers Groep banner" />
    </section>
    <section class="content pt-18 pb-32">
      <div class="container flex flex-wrap">
        <div v-for="blogItem in blog" class="px-8 w-full mb-12">
          <a
            :href="
              localePath({
                name: 'blog-slug',
                params: { slug: blogItem.slug }
              })
            "
            class="flex flex-wrap"
          >
            <div class="w-full tablet:w-1/4 tablet:h-full flex tablet:pr-4">
              <img
                :src="blogItem.acf.image.sizes.medium_large"
                alt="icon"
                class="m-auto"
                style="width: 100%;height: 200px;object-fit: cover;"
              />
            </div>
            <div
              class="w-full tablet:w-3/4 text-center tablet:text-left tablet:pl-4"
            >
              <h6 class="mt-6 tablet:mt-0">
                {{ blogItem.title.toUpperCase() }}
              </h6>
              <span class="text-grey text-3xl">
                &bull; &bull; &bull;
              </span>
              <br />
              <div
                v-html="
                  `${blogItem.acf.text.slice(
                    0,
                    255
                  )}...    <br><strong>Lees verder...</strong>`
                "
                class="text-left"
              ></div>
            </div>
          </a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Blog',
  async asyncData({ store, route }) {
    const [page, blog] = await Promise.all([
      wordpress.get(`page${route.fullPath}`),
      wordpress.get(
        `category/${
          store.state.language === 'nl' ? 'blog' : 'blog-en'
        }?per_page=999&orderby=menu_order`
      )
    ])

    return {
      page: page.data,
      blog: blog.data.reverse()
    }
  }
}
</script>
